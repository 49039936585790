<template>
    <svg
      class="icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M 7.2 18 C 1.8 18 1.8 23.4 5.4 23.4 C 9 23.4 9 21.6 9 19.8 L 9 7.2 C 13 8 13 10 14 14 C 17 6 12 9 8 3 L 7 3 L 7.2 18"
        stroke-width="0"
        fill="currentColor"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: "IconOneNote",
  };
  </script>
  
  <style scoped>
  .icon {
    display: block;
  }
  </style>
  

