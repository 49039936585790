<template>
  <div class="about">
    <h1>About page</h1>    
  </div>
</template>



<style scoped>
.about {
  margin: 4em;
  color: white;
}
</style>

