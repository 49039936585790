import { createRouter, createWebHistory } from 'vue-router';
import AboutView from '../views/AboutView.vue';
import GalerieView from '../views/GalerieView.vue';
import HomeView from '../views/HomeView.vue';
// import KontaktView from '../views/KontaktView.vue';
import KonzerteView from '../views/KonzerteView.vue';
import RepertoireView from '../views/RepertoireView.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  // {
  //   path: '/dashboard',
  //   name: 'DashBoard',
  //   component: () => import('../views/DashBoard.vue')
  // },
  {
    path: '/aboutview',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
  },
  {
    path: '/galerieview',
    name: 'GalerieView',    
    component: GalerieView,
  },
  {
    path: '/KontaktView',
    name: 'KontaktView',    
    component: () => import('../views/KontaktView.vue')
  },
  {
    path: '/KonzerteView',
    name: 'KonzerteView',    
    component: KonzerteView,
  },
  {
    path: '/RepertoireView',
    name: 'RepertoireView',    
    component:RepertoireView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
