<template>
  <svg
    class="icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path
      d="M 7.2 18 C 1.8 18 1.8 23.4 5.4 23.4 C 9 23.4 9 21.6 9 19.8 L 9 7.2 L 17.9748 5.3334 L 18 16.2 C 12.6 16.2 12.6 21.6 16.2 21.6 C 19.8 21.6 19.8 19.8 19.8 18 L 19.8 3.6 C 19.8 1.836 19.6254 1.8504 18.81 1.9584 L 9 3.6 C 7.6896 3.7764 7.1946 4.2318 7.2 5.4 L 7.2 18"
      stroke-width="0"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTwoNotes",
};
</script>

<style scoped>
.icon {
  display: block;
}
</style>
