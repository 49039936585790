<template>
    <div class="repertoire">
      <h1>Repertoire</h1>
    </div>
  </template>

   
<style scoped>
.repertoire {
  margin: 1em;
  color: white;
  min-height: 90vh;
}
</style>
