<template>
  <div class="main-view-header">
    <div class="header-title">
      <span>DAS DUO </span>
      <span>"Piano Moments"</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComp",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-view-header {
  display: flex;
}
.header-title {
  margin-left: 50px;
  margin-top: 7px;
  margin-right: 7px;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-right: 40px;
  font-size: xx-large;
  color: white;
  font-weight: 900;
  background-color: rgb(146, 146, 146, 0.6);
}
</style>
